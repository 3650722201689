<template>
  <div>
    <div
      v-for="promotion in promotions"
      :key="promotion"
    >
      <b-card>
        <div style="display: flex; justify-content:space-between; align-items: center; font-size: 1em;">
          <span>
            {{ promotion.id }} | nowa cena: {{ promotion.new_price }}
          </span>
          <span>
            <router-link :to="{ name: 'Promotions-edit', params: {id: promotion.id} }">
              <button class="btn btn-primary">
                Więcej info
              </button>
            </router-link>
          </span>
        </div>
      </b-card>
    </div>

    <b-card v-if="!promotions[0]">
      <h2>Nie posiadasz promocji!</h2>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import {
  BCard, BCardText, BForm, Bswich, BFormSelect, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Promotions',
  components: {
    BCard,
    BCardText,
    BForm,
    Bswich,
    BFormSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      promotions: [],
      loading: true,
      error: null,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0,
      },
      sort: {
        sort_by: 'id',
        sort_type: 'desc',
      },
      filters: {
        search: '',
      },
    }
  },
  mounted() {
    document.title = 'Lista promocji - Pickmode'
    this.fetchPromotions()
  },
  methods: {
    fetchPromotions() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.get(`${process.env.VUE_APP_API_URL}promotion`, config)
        .then(response => {
          this.promotions = response.data.promotions
        })
      // catch error
        .catch(error => {
          this.error = error.response.data.error
          this.loading = false
        })
    },
  },
}
</script>

<style>

</style>
